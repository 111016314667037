// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-pages-404-jsx": () => import("./../../../node_modules/@gatsbystorefront/gatsby-theme-storefront-shopify/src/pages/404.jsx" /* webpackChunkName: "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-pages-404-jsx" */),
  "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-templates-cart-index-jsx": () => import("./../../../node_modules/@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/cart/index.jsx" /* webpackChunkName: "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-templates-cart-index-jsx" */),
  "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-templates-catalog-index-jsx": () => import("./../../../node_modules/@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/catalog/index.jsx" /* webpackChunkName: "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-templates-catalog-index-jsx" */),
  "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-templates-main-index-jsx": () => import("./../../../node_modules/@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/main/index.jsx" /* webpackChunkName: "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-templates-main-index-jsx" */),
  "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-templates-policy-index-jsx": () => import("./../../../node_modules/@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/policy/index.jsx" /* webpackChunkName: "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-templates-policy-index-jsx" */),
  "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-templates-product-index-jsx": () => import("./../../../node_modules/@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/index.jsx" /* webpackChunkName: "component---node-modules-gatsbystorefront-gatsby-theme-storefront-shopify-src-templates-product-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */)
}

